import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    common: {
      siteHeaderText: "<0>Tims Rewards</0> Offers Performance Dashboard",
      language: "Language",
      faq: "Frequently Asked Questions",
      faqLink: "https://shareable-documents.s3.amazonaws.com/LoyaltyDashboard/loyalty-dashboard-faq-en.pdf",
      signOut: "Sign Out",
      storeSelectorLabel: "Restaurant",
      weekSelectorLabel: "Period",
      weekSelectorFormat: "[Week] W[:] MMMM D, YYYY",
      ok: "OK",
      cancel: "Cancel",
      previousWeek: "Previous week",
      nextWeek: "Next week",
      dataNotFound: "There is no data available for this store and period.",
      summaryHeading: "Summary",
      performanceDetailsHeading: "Transactions with an Offer",
      offerIncrementalityHeading: "Offer Incrementality",
    },
    summary: {
      thisWeekHeading: "This Week",
      trailingWeekAverageHeading: "Trailing {{trailingWeeks}}-Week Average",
      totalWklyIncrementalSalesHeading: "Total Weekly Incremental Sales",
      salesIncreaseFromGuestsUsingOffersHeading: "% Sales Increase from Guests using Offers",
      loyaltyTransactionsPercentageHeading: "% Loyalty Transactions",
      registeredLoyaltyTransactionsPercentageHeading: "% Registered Loyalty Transactions of Total Transactions",
      offerTransactionsPercentageHeading: "% Offer Transactions of Total Transactions",
    },
    summary2: {
      graphHeading: "Graph",
      totalOfferTransactions: "Total Offer Transactions",
      summaryHeading: "Summary",
      increasedSalesFromLoyaltyOffers: "Increased Sales from Offers",
      salesValueOfLoyaltyRedemptions: "Sales Value of Loyalty Redemptions",
      netSalesImpactFromLoyalty: "Net Sales Impact from Loyalty",
      increasedGrossMarginFromLoyaltyOffers: "Increased Gross Margin from Offers",
      grossMarginWithoutLoyaltyProgram: "Gross Margin without Loyalty Program",
      grossMarginImpactFromLoyalty: "Gross Margin Impact from Loyalty",
      summaryOfOfferGuestImpactHeading: "Summary of Offer Guest Impact",
      additionalVisitsDueToOffersThisWeek: "Additional Visits due to Offers This Week",
      spendIncreaseForOfferUsersVsNonOfferUsersPercentage: "% Spend Increase for Offer Users vs Non- Offer Users",
      restaurant: "Restaurant",
      regionalAverage: "Regional Average",
      nationalAverage: "National Average",
      thisWeekHeading: "This Week",
      thisQuarter: "This Quarter",
      lastQuarter: "Last Quarter",
      thisQuarterLastYear: "This Quarter, Last Year",
      thisWeek: "This Week",
      weeklyAverage: "Weekly Average",
    },
    offerPerformance: {
      categoryHeading: "Category",
      quantityRedeemedHeading: "Quantity Redeemed",
      averageChequeHeading: "Average Cheque",
      ofWhichAttachedSalesHeading: "Of Which Attached Sales",
      blendedFPOfOfferTransactionsHeading: "Blended F&P% of Offer Transactions",
      gmOnChequeHeading: "GM$ on Cheque",
      freeRewardHeading: "Free Reward",
      totalOffersRedeemedThisWeekHeading: "Total Offers Redeemed This Week",
      pointsOffersHeading: "Points Offers",
      valueOffersHeading: "Value Offers",
      averageHeading: "Average",
      totalHeading: "Total",
    },
    offerIncrementality: {
      averageChequeByGuestTypeHeading: "Average Cheque comparison between guests who used offers vs guests who did not",
      thisWeekHeading: "This Week",
      trailingWeekAverageHeading: "Trailing {{trailingWeeks}}-Week",
      guestWhoUsedOffersHeading: "Guests who Used Offers",
      guestWhoDidNotUseOffersHeading: "Guests who Did Not Use Offers",
      differenceInChequeHeading: "Difference in Cheque per Visit",
      weeklyChequeByGuestTypeHeading: "Average Weekly Frequency comparison between guests who used offers vs guests who did not",
      visitFrequencyHeading: "Visit Frequency",
      differenceInWeeklyHeading: "Difference in Weekly Visits",
    },
    tooltip: {
      increasedSalesFromLoyaltyOffers:
        "This is a measure of the difference in sales between registered members who used offers in the current week compared to the sales from registered members who did not use offers. It is used to show how much additional sales are being driven by users who use offers.",
      salesValueOfLoyaltyRedemptions:
        "This is the sales price of items that were redeemed by a guest for free with points. For example, if 100 coffees of an average selling price of $2 were redeemed for free during this week, this figure would be $(200).",
      netSalesImpactFromLoyalty:
        "This is the increased sales your restaurant experienced as a result of guests using loyalty offers and spending more than a non-offer user, taking away the value of loyalty reward redemptions during that week.",
      increasedGrossMarginFromLoyaltyOffers: "This is the gross margin earned on the increased sales from loyalty offers",
      grossMarginWithoutLoyaltyProgram:
        "This is the gross margin that would have been earned on products that were given away for free through Tim’s Rewards, had they been regular sales. Gross Margin is calculated as the sales value less the F&P costs of the items.",
      grossMarginImpactFromLoyalty:
        "This is the “Gross Margin with Loyalty Program” less “Gross Margin without Loyalty Program” lines. It shows the approximate gross margin impact of the loyalty program on a weekly basis. Note this is the most conservative viewpoint which estimates that without the Tims Rewards program, all of the free products given away this week would have been sold at regular price. This causes the overall benefit on gross margin of Tims Rewards to be understated.",
      additionalVisitsDueToOffersThisWeek:
        "This is the difference in frequency for members who used offers compared to members who didn’t use offers, multiplied by the number of guests who used offers this week. It shows how many more transactions this week were driven by presenting offers to Tims Rewards members.",
      spendIncreaseForOfferUsersVsNonOfferUsersPercentage:
        "This measures the weekly spend of an average loyalty member who used offers compared to the weekly spend of an average member that did not use offers this week.",
      totalWklyIncrementalSales:
        "This is a measure of the difference in sales between registered members who used offers in the current week compared to the sales from registered members who did not use offers.",
      spendOfferGuest: "This compares the weekly spend of an average loyalty member who uses offers compared to an average member that does not use offers.",
      gmOfferTxns: "This is the total gross margin percentage (topline sales less F&P) on transactions where an offer was redeemed.",
      percentageLoyaltyTxns:
        "This is the percentage of total transactions at the restaurant that had a loyalty scan, whether it was a plastic card or via the app.",
      registeredLoyaltyTxns: "This is the percentage of total transactions at the restaurant that had a loyalty scan with a linked card or app.",
      offerTxnsTotal: "This is the percentage of transactions at the restaurant that had an offer redeemed as part of the transaction.",
      freeReward: "These are the transactions that included a product being redeemed for points (e.g. 70 points redeemed for a coffee).",
      pointsOffers: "These are transactions for which offers with points incentives were used (e.g. 2X the points if you make a purchase of $10 or more).",
      valueOffers: "These are transactions that offer the guest a price discount (e.g. 2 for $4 Iced Capp)",
      ofWhichAttachedSales:
        "These are the full-priced items purchased as part of the offer transaction. For instance, for a value offer of 2 for $4 Iced Capp, the attached sales portion represents the sales coming from products that were purchased in addition to the two Iced Cappuccinos at full price.",
      fpOfferTxns: "This is the average F&P% on offer transactions at the restaurant for the selected week.",
      gmOnCheque: "This is the average cheque value for these transactions less the F&P cost for the transactions.",
      averageChequeByGuestType:
        "This is the average cheque value per visit that is driven by registered loyalty members who use offers compared to those who do not.",
      avgWklyFreqByGuestType: "This is the average weekly visit frequency driven by registered loyalty members who use offers compared to those who do not.",
      totalOffersRedeemedThisWeek: "Total transactions that contained an offer",
    },
  },
  fr: {
    common: {
      siteHeaderText: "Tableau de bord des performances de l'offre <0>FidéliTim</0>",
      language: "Langue",
      faq: "Questions Fréquentes",
      faqLink: "https://shareable-documents.s3.amazonaws.com/LoyaltyDashboard/loyalty-dashboard-faq-fr.pdf",
      signOut: "Se déconnecter",
      storeSelectorLabel: "Restaurant",
      weekSelectorLabel: "Période",
      weekSelectorFormat: "[Semaine] W[:] D MMMM YYYY",
      ok: "Ok",
      cancel: "Annuler",
      previousWeek: "Semaine précédente",
      nextWeek: "Prochaine semaine",
      dataNotFound: "Aucune donnée n’est disponible pour cet établissement et cette période.",
      summaryHeading: "Sommaire",
      performanceDetailsHeading: "Transactions avec une offre",
      offerIncrementalityHeading: "Incrémentalité des offres",
    },
    summary: {
      thisWeekHeading: "Cette semaine",
      trailingWeekAverageHeading: "Moyenne des {{trailingWeeks}} dernières semaines",
      totalWklyIncrementalSalesHeading: "Total des ventes incrémentielles hebdomadaires",
      salesIncreaseFromGuestsUsingOffersHeading: "% d’augmentation des ventes attribuables aux invités qui utilisent les offres",
      loyaltyTransactionsPercentageHeading: "% des transactions FidéliTim",
      registeredLoyaltyTransactionsPercentageHeading: "% des transactions de membres FidéliTim inscrits par rapport au total des transactions",
      offerTransactionsPercentageHeading: "% des transactions avec offre par rapport au total des transactions",
    },
    summary2: {
      graphHeading: "Graphique",
      totalOfferTransactions: "Total des transactions avec offre",
      summaryHeading: "Sommaire",
      increasedSalesFromLoyaltyOffers: "Hausse des ventes grâce aux offres",
      salesValueOfLoyaltyRedemptions: "Valeur de vente des échanges de fidélité",
      netSalesImpactFromLoyalty: "Impact de FidéliTim sur les ventes nettes",
      increasedGrossMarginFromLoyaltyOffers: "Hausse de la marge brute grâce aux offres",
      grossMarginWithoutLoyaltyProgram: "Marge brute sans programme de fidélité",
      grossMarginImpactFromLoyalty: "Impact de la fidélité sur la marge brute",
      summaryOfOfferGuestImpactHeading: "Résumé de l’impact des offres sur les invités",
      additionalVisitsDueToOffersThisWeek: "Visites supplémentaires attribuables à des offres cette semaine",
      spendIncreaseForOfferUsersVsNonOfferUsersPercentage:
        "% d’augmentation des dépenses pour les invités qui utilisent les offres c. les invités qui n’en utilisent pas",
      restaurant: "Restaurant",
      regionalAverage: "Moyenne régionale",
      nationalAverage: "Moyenne nationale",
      thisWeekHeading: "Cette semaine",
      thisQuarter: "Ce trimestre",
      lastQuarter: "Trimestre précédent",
      thisQuarterLastYear: "Ce trimestre\nl'année précédente",
      thisWeek: "Cette semaine",
      weeklyAverage: "Moyenne hebdomadaire",
    },
    offerPerformance: {
      categoryHeading: "Catégorie",
      quantityRedeemedHeading: "Quantité réclamée",
      averageChequeHeading: "Facture moyenne",
      ofWhichAttachedSalesHeading: "Autres produits achetés",
      blendedFPOfOfferTransactionsHeading: "% des coûts A/P combinés des transactions avec offre",
      gmOnChequeHeading: "MB en $ sur la facture",
      freeRewardHeading: "Récompense gratuite",
      totalOffersRedeemedThisWeekHeading: "Total des offres échangées cette semaine",
      pointsOffersHeading: "Offres de points",
      valueOffersHeading: "Offres de valeur",
      averageHeading: "Moyenne",
      totalHeading: "Total",
      totalOffersRedeemedThisWeek: "Total transactions that contained an offer",
    },
    offerIncrementality: {
      averageChequeByGuestTypeHeading: "Comparaison de la facture moyenne entre les invités qui ont utilisé une offre et ceux qui ne l’ont pas fait",
      thisWeekHeading: "Cette semaine",
      trailingWeekAverageHeading: "{{trailingWeeks}} dernières semaines",
      guestWhoUsedOffersHeading: "Invités qui ont utilisé des offres",
      guestWhoDidNotUseOffersHeading: "Invités qui n’ont pas utilisé d’offre",
      differenceInChequeHeading: "Écart de la facture par visite",
      weeklyChequeByGuestTypeHeading:
        "Comparaison de la fréquence moyenne des visites par semaine entre les invités qui ont utilisé une offre et ceux qui ne l’ont pas fait",
      visitFrequencyHeading: "Fréquence des visites",
      differenceInWeeklyHeading: "Écart du nombre de visites par semaine",
    },
    tooltip: {
      increasedSalesFromLoyaltyOffers:
        "La différence entre le montant des ventes générées par les membres inscrits qui ont utilisé des offres durant la semaine actuelle et celui des ventes générées par ceux qui n’en ont pas utilisé. Elle indique le montant des ventes additionnelles générées par les membres qui utilisent les offres.",
      salesValueOfLoyaltyRedemptions:
        "Le prix total des produits que les invités ont obtenus gratuitement en échange de leurs points. Par exemple : si 100 cafés gratuits au prix moyen de 2 $ ont été réclamés la semaine en question, cette somme sera de 200 $.",
      netSalesImpactFromLoyalty:
        "Il s'agit de l'augmentation des ventes que votre restaurant a subie en raison des clients utilisant des offres de fidélité et dépensant plus qu'un utilisateur sans offre, ce qui réduit la valeur des échanges de récompenses de fidélité au cours de cette semaine.",
      increasedGrossMarginFromLoyaltyOffers: "La marge brute obtenue sur les ventes additionnelles générées par les offres FidéliTim indiquées plus haut.",
      grossMarginWithoutLoyaltyProgram:
        "La marge brute qui aurait été obtenue sur les produits donnés gratuitement dans le cadre de FidéliTim, s’il s’agissait de ventes régulières. La marge brute représente la valeur des ventes moins les coûts A/P des produits.",
      grossMarginImpactFromLoyalty:
        "La « marge brute avec FidéliTim » moins la « marge brute sans FidéliTim ». Ce résultat indique l’impact approximatif sur la marge brute du programme FidéliTim chaque semaine. Veuillez prendre note qu’il estime de manière conservatrice que sans le programme FidéliTim, tous les produits donnés gratuitement la semaine en question auraient été vendus au prix régulier. Par conséquent, l’impact positif total de FidéliTim sur la marge brute est sous-estimé.",
      additionalVisitsDueToOffersThisWeek:
        "Il s’agit de la différence entre la fréquence de visite des membres qui ont utilisé des offres et celle des membres qui n’ont pas utilisé d’offres, multipliée par le nombre d’invités qui ont utilisé des offres la semaine en question. Ce résultat indique le nombre de transactions additionnelles générées par FidéliTim durant cette période.",
      spendIncreaseForOfferUsersVsNonOfferUsersPercentage:
        "La comparaison entre les dépenses hebdomadaires d’un membre FidéliTim moyen qui utilise des offres et celles d’un membre moyen qui n’en a pas utilisé la semaine en question.",
      totalWklyIncrementalSales:
        "Différence entre le montant des ventes générées par les membres inscrits qui ont utilisé des offres durant la semaine actuelle et celui des ventes générées par ceux qui n’en ont pas utilisé.",
      spendOfferGuest:
        "Comparaison entre les dépenses hebdomadaires d’un membre FidéliTim moyen qui utilise des offres et celles d’un membre moyen qui n’en utilise pas.",
      gmOfferTxns: "Pourcentage de la marge brute totale (ventes additionnelles moins les coûts A/P) pour les transactions où une offre a été réclamée.",
      fpOfferTxns: "Pourcentage des coûts A/P moyens pour les transactions avec offres effectuées au restaurant durant la semaine sélectionnée.",
      percentageLoyaltyTxns: "Pourcentage du total des transactions au restaurant où une carte (de plastique ou via l’appli) a été numérisée.",
      registeredLoyaltyTxns:
        "Pourcentage du total des transactions effectuées au restaurant où une carte de plastique associée ou une carte numérique sur l’appli a été numérisée.",
      offerTxnsTotal: "Pourcentage des transactions effectuées au restaurant pour lesquelles une offre a été réclamée.",
      freeReward: "Transactions pour lesquelles des points ont été échangés contre un produit (par exemple, 70 points échangés contre un café).",
      pointsOffers:
        "Transactions pour lesquelles des offres offrant des points à titre d’incitatifs ont été utilisées (p. ex., 2 fois les points avec un achat de 10 $ ou plus).",
      valueOffers: "Transactions pour lesquelles l’invité bénéficie d’une réduction de prix (p. ex., 2 cappuccinos glacés pour 4 $).",
      ofWhichAttachedSales:
        "Produits achetés au prix régulier dans le cadre d’une transaction incluant une offre. Par exemple, pour une offre de valeur proposant 2 cappuccinos glacés pour 4 $, la portion des « autres produits achetés » représente les produits qui ont été achetés au prix régulier en plus des deux cappuccinos glacés.",
      gmOnCheque: "Valeur moyenne de la facture pour ces transactions moins les coûts A/P.",
      averageChequeByGuestType:
        "Valeur de la facture moyenne des membres FidéliTim inscrits qui utilisent des offres par rapport à celle de ceux qui ne le font pas.",
      wklyFreqByGuestType:
        "Fréquence moyenne des visites hebdomadaires des membres FidéliTim inscrits qui utilisent des offres par rapport à ceux qui ne le font pas.",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(
    {
      resources,
      ns: ["common", "offerPerformance", "offerIncrementality", "summary", "summary2"],
      defaultNS: "common",
      fallbackLng: "en",
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safeguards from xss
      },
    },
    () => {
      document.documentElement.setAttribute("lang", i18n.language);
      i18n.on("languageChanged", (lng) => {
        document.documentElement.setAttribute("lang", lng);
      });
    },
  );

export default i18n;
