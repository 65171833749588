import React from "react";
import moment from "moment";
import { IconButton, withStyles, Theme } from "@material-ui/core";
import { DatePicker, DatePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { createStyles, StyledComponentProps } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import MomentUtils from "@date-io/moment";

type StyledDatePickerProprs = DatePickerProps & StyledComponentProps;

const WeekPicker: React.FunctionComponent<StyledDatePickerProprs> = (props: StyledDatePickerProprs) => {
  const { classes, ...otherProps } = props;

  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const localeMap = new Map<string, string>([
    ["en", "en-ca"],
    ["fr", "fr-ca"],
  ]);
  moment.locale(localeMap.get(i18n.language), {
    week: {
      dow: 1, // setting Monday as the first day of the week
    },
  });

  const renderDay = (date: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean): JSX.Element => {
    const dateClone = moment(date);
    const selectedDateClone = moment(selectedDate);

    const start = moment(selectedDateClone).startOf("week");
    const end = moment(selectedDateClone).endOf("week");

    const wrapperClassName =
      classes &&
      [
        start <= dateClone && dateClone <= end ? classes.highlight : "",
        dateClone.isSame(start) ? classes.firstHighlight : "",
        dateClone.isSame(end, "day") ? classes.endHighlight : "",
      ].join(" ");

    const dayClassName =
      classes &&
      [
        classes.day,
        !dayInCurrentMonth ? classes.nonCurrentMonthDay : "",
        dateClone.isAfter(moment()) ? classes.disabledDay : "",
        dateClone.isBefore(props.minDate) ? classes.disabledDay : "",
      ].join(" ");

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span>{dateClone.format("D")}</span>
        </IconButton>
      </div>
    );
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
      <DatePicker {...otherProps} renderDay={renderDay} okLabel={t("ok")} cancelLabel={t("cancel")} />
    </MuiPickersUtilsProvider>
  );
};

const styles = createStyles((theme: Theme) => ({
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  disabledDay: {
    color: theme.palette.text.disabled,
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(WeekPicker);
