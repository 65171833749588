import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { AppBar, ThemeProvider, Toolbar, createMuiTheme, Button, Menu, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Config from "./config";
import i18n from "./i18n";
import Home from "./pages/home";
import Login from "./pages/login";
import Store from "./pages/store";
import "./app.css";

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

const redirectToLogin = (): void => {
  window.location.assign(Config.loginUri);
};

function App(): JSX.Element {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#c8102e",
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "0.75rem",
        },
      },
    },
  });
  const location = useLocation();
  const { t } = useTranslation();
  const [initialized, setInitialized] = React.useState(false);

  const signOut = (): void => {
    localStorage.removeItem("id_token");
    window.location.assign(Config.logoutUri);
  };

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const setLanguage = (language: string): void => {
    i18n.changeLanguage(language);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getFAQ = (): void => {
    window.open(t("faqLink"));
  };

  React.useEffect(() => {
    if (!initialized) {
      Axios.interceptors.response.use(
        (value: AxiosResponse) => {
          return value;
        },
        () => {
          redirectToLogin();
        },
      );
      Axios.interceptors.request.use((value: AxiosRequestConfig) => {
        value.headers.Authorization = localStorage.getItem("id_token");
        return value;
      });
    }

    const hash = new Map<string, string>(
      location.hash
        .split(/[&#]/)
        .filter((i): string => i)
        .map((i: string): [string, string] => i.split("=") as [string, string]),
    );
    const idToken = hash.get("id_token");
    if (idToken) {
      localStorage.setItem("id_token", idToken);
    }
    if (!idToken && !localStorage.getItem("id_token")) {
      redirectToLogin();
    }
    setInitialized(true);
  }, [location, initialized]);

  return !initialized ? (
    <></>
  ) : (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppBar id="header" position="static">
          <Toolbar>
            <h1>
              <Trans i18nKey="siteHeaderText">
                <strong className="logo-tims-rewards">Tims Rewards</strong> Dashboard
              </Trans>
            </h1>
            <div>
              <Button onClick={handleMenu} color="inherit">
                {t("language")}
              </Button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose} id="language-select">
                <MenuItem onClick={(): void => setLanguage("en")}>English</MenuItem>
                <MenuItem onClick={(): void => setLanguage("fr")}>Français</MenuItem>
              </Menu>
            </div>
            <Button onClick={getFAQ} color="inherit">
              {t("faq")}
            </Button>
            <Button color="inherit" onClick={(): void => signOut()}>
              {t("signOut")}
            </Button>
          </Toolbar>
        </AppBar>
        <div id="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/store/" element={<Store />} />
            <Route path="/store/:storeId" element={<Store />} />
            <Route path="/store/:storeId/:date" element={<Store />} />
          </Routes>
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
