import React from "react";
import { Button, TextField, InputAdornment, Container } from "@material-ui/core";

const Login: React.FunctionComponent = () => {
  return (
    <Container className="page-login" maxWidth="xs">
      <form>
        <TextField
          id="email"
          label="email address"
          aria-label="timzone email address"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">@timzone.com</InputAdornment>,
          }}
        />
        <TextField id="password" label="password" type="password" fullWidth />
        <div className="form-actions">
          <Button variant="contained" color="primary">
            Login
          </Button>
          {/* <Button variant="text">Reset your password</Button> */}
        </div>
      </form>
    </Container>
  );
};

export default Login;
