import i18n from "i18next";

const getLocale = (): string => {
  return i18n.language?.substr(0, 2) === "fr" ? "fr-ca" : "en-ca";
};

export const formatMoney = (input: number, decimals = 2): string => {
  return new Intl.NumberFormat(getLocale(), { style: "currency", currency: "CAD", minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(
    input,
  );
};

export const formatNumber = (input: number): string => {
  return Number(input).toLocaleString(getLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
