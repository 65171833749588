import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import Tooltip from "./tooltip";
import { OfferPerformanceData } from "../models";
import { formatMoney, formatNumber } from "../utils/formatter";

type Props = {
  data: {
    freeRewards: OfferPerformanceData;
    pointsOffers: OfferPerformanceData;
    valueOffers: OfferPerformanceData;
  };
};
type OPDataKeys = Array<keyof OfferPerformanceData>;

const OfferPerformance: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { t } = useTranslation("offerPerformance");

  useEffect(() => {
    const newAverage = { quantity: 0, averageCheque: 0, attachedSales: 0, fp: 0, gm: 0 };
    const newTotal = { quantity: 0, averageCheque: 0, attachedSales: 0, fp: 0, gm: 0 };
    const totalQuantities = { quantity: 0, averageCheque: 0, attachedSales: 0, fp: 0, gm: 0 };

    Object.values(data).forEach((row) => {
      (Object.keys(row) as OPDataKeys).forEach((key) => {
        newTotal[key] += row[key] * (key === "quantity" ? 1 : row.quantity);
        if (key !== "quantity" && row[key] !== Number.MIN_VALUE) {
          // MIN_VALUE represents N/A, so we don't want to include that in total
          totalQuantities[key] += row.quantity;
        }
      });
    });
    (Object.keys(newTotal) as OPDataKeys).forEach((key) => {
      newAverage[key] = newTotal[key] / (key === "quantity" ? Object.keys(data).length : totalQuantities[key]);
    });
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "8rem" }}>{t<string>("categoryHeading")}</TableCell>
            <TableCell align="right">{t<string>("quantityRedeemedHeading")}</TableCell>
            <TableCell align="right">{t<string>("averageChequeHeading")}</TableCell>
            <TableCell align="right">
              {t<string>("blendedFPOfOfferTransactionsHeading")}
              <Tooltip i18nKey="fpOfferTxns" />
            </TableCell>
            <TableCell align="right">
              {t<string>("gmOnChequeHeading")}
              <Tooltip i18nKey="gmOnCheque" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {t<string>("pointsOffersHeading")}
              <Tooltip i18nKey="pointsOffers" />
            </TableCell>
            <TableCell align="right">{data.pointsOffers.quantity}</TableCell>
            <TableCell align="right">{formatMoney(data.pointsOffers.averageCheque)}</TableCell>
            <TableCell align="right">{formatNumber(data.pointsOffers.fp * 100)} %</TableCell>
            <TableCell align="right">{formatMoney(data.pointsOffers.gm)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {t<string>("valueOffersHeading")}
              <Tooltip i18nKey="valueOffers" />
            </TableCell>
            <TableCell align="right">{data.valueOffers.quantity}</TableCell>
            <TableCell align="right">{formatMoney(data.valueOffers.averageCheque)}</TableCell>
            <TableCell align="right">{formatNumber(data.valueOffers.fp * 100)} %</TableCell>
            <TableCell align="right">{formatMoney(data.valueOffers.gm)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfferPerformance;
