const dev = {
  apiUrl: "https://lsuugwu9p7.execute-api.us-east-1.amazonaws.com/v0",
  // Change https://dashboard.rbisandbox.com to http://localhost:3000 when working locally
  loginUri:
    "https://tims-rewards-dashboard-exp.auth.us-east-1.amazoncognito.com/login?redirect_uri=https://dashboard.rbisandbox.com&response_type=token&client_id=58t2vgduioul8teb9072lqvq2e&scope=email+openid+profile",
  logoutUri:
    "https://tims-rewards-dashboard-exp.auth.us-east-1.amazoncognito.com/logout?client_id=58t2vgduioul8teb9072lqvq2e&logout_uri=https://dashboard.rbisandbox.com",
  cognito: {
    region: "us-east-1",
    userPoolId: "us-east-1_mG1pb6Or3",
    userPoolWebClientId: "4964feov8n66nmj9s5rk21c3ki",
  },
};

const prod = {
  apiUrl: "https://5p9i0jsot1.execute-api.us-east-1.amazonaws.com/v0",
  loginUri:
    "https://tims-rewards-dashboard-prod.auth.us-east-1.amazoncognito.com/login?redirect_uri=https://dashboard.rbi.digital&response_type=token&client_id=58aq75geqoc2165if64i6d13pg&scope=email+openid+profile",
  logoutUri:
    "https://tims-rewards-dashboard-prod.auth.us-east-1.amazoncognito.com/logout?client_id=58aq75geqoc2165if64i6d13pg&logout_uri=https://dashboard.rbi.digital",
  cognito: {
    region: "us-east-1",
    userPoolId: "us-east-1_WPal3i0Wi",
    userPoolWebClientId: "63aqtaeeog37d6fcjjs0eao160",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_ENV === "prod" ? prod : dev;
export default config;
