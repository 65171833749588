/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, FormControlLabel, IconButton } from "@material-ui/core";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ComposedChart, CartesianGrid, XAxis, YAxis, Bar, Cell, ResponsiveContainer, Tooltip as ChartTooltip, Legend, Line, LabelList } from "recharts";
import Tooltip from "./tooltip";
import { RawData } from "../models";
import { formatNumber } from "../utils/formatter";

type Props = {
  data: RawData;
};

const StoreSummaryPD: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { t } = useTranslation("summary2");

  if (!data) {
    return <></>;
  }

  return (
    <>
      <Paper elevation={2} className="container-table">
        <h4>
          {t<string>("summaryOfOfferGuestImpactHeading")}
          <Tooltip i18nKey="summaryOfOfferGuestImpactHeading" />
        </h4>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("additionalVisitsDueToOffersThisWeek")}
                  <Tooltip i18nKey="additionalVisitsDueToOffersThisWeek" />
                </TableCell>
                <TableCell align="right">{data.AdditionalVisits}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("spendIncreaseForOfferUsersVsNonOfferUsersPercentage")}
                  <Tooltip i18nKey="spendIncreaseForOfferUsersVsNonOfferUsersPercentage" />
                </TableCell>
                <TableCell align="right">{formatNumber(data.SpendIncreaseOfOfferGuestsOverNonOfferGuests * 100)} %</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default StoreSummaryPD;
