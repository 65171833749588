import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

type Props = {
  i18nKey: string;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
};

const Tooltip: React.FunctionComponent<Props> = ({ i18nKey, placement = "top" }: Props) => {
  const { t } = useTranslation("tooltip");
  return (
    <MuiTooltip placement={placement} title={t<string>(i18nKey)} className="icon-tooltip" arrow>
      <HelpIcon />
    </MuiTooltip>
  );
};

export default Tooltip;
