import { OfferIncrementalityDataSet, OfferPerformanceDataSet, RawData, SummaryData } from "../models";

export const calculateRawDataAverage = (items: RawData[]): RawData => {
  const average: RawData = { ...items[0] };

  for (let i = 1; i < items.length; i++) {
    for (const prop in average) {
      if (typeof average[prop] === "number") {
        average[prop] += items[i][prop];
      }
    }
  }

  for (const prop in average) {
    if (typeof average[prop] === "number") {
      average[prop] /= items.length;
    }
  }

  return average;
};

export const createOfferPerformance = (data: RawData): OfferPerformanceDataSet => ({
  freeRewards: {
    quantity: data.free_reward_sum_trxns_consolidated,
    averageCheque: data.free_reward_avg_check_consolidated,
    attachedSales: data.free_reward_avg_attach_sales,
    fp: data.free_reward_fp_percentage,
    gm: data.gm_free_reward,
  },
  pointsOffers: {
    quantity: data.regist_points_offer_trxns,
    averageCheque: data.regist_points_offer_avg_check,
    attachedSales: data.regist_points_offer_avg_check, // same as above
    fp: data.regist_points_offer_fp_percentage,
    gm: data.gm_points_offer,
  },
  valueOffers: {
    quantity: data.regist_value_offer_trxns,
    averageCheque: data.regist_value_offer_avg_check,
    attachedSales: data.value_offer_avg_attach_sales,
    fp: data.regist_value_offer_fp_percentage,
    gm: data.gm_value_offer,
  },
});

export const createOfferIncrementality = (op: OfferPerformanceDataSet, data: RawData): OfferIncrementalityDataSet => {
  return {
    cheque: {
      wOffers: data.cust_used_offers_avg_check,
      woOffers: data.cust_didnt_use_offers_avg_check,
    },
    traffic: {
      wOffers: data.cust_used_offers_avg_freq,
      woOffers: data.cust_didnt_use_offers_avg_freq,
    },
  };
};

export const calculateSummary = (op: OfferPerformanceDataSet, oi: OfferIncrementalityDataSet, data: RawData): SummaryData => {
  const { cheque, traffic } = oi;
  const salesWithOffers = cheque.wOffers * traffic.wOffers;
  const salesWithoutOffers = cheque.woOffers * traffic.woOffers;
  const blendedFP = 31.2;

  return {
    salesIncrease: (salesWithOffers - salesWithoutOffers) * data.cust_used_offers_total_cust_consolidated,
    salesIncreaseFromOffers: ((salesWithOffers - salesWithoutOffers) / salesWithoutOffers) * 100,
    blendedGM: 100 - blendedFP + 3,
    blendedFP,
    loyaltyPenetration: data.loyalty_pen * 100,
    registeredLoyalty: data.loyalty_regist_pen * 100,
    offerPenetration: data.offer_pen * 100,
  };
};
