import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, FormControlLabel } from "@material-ui/core";
import { ComposedChart, CartesianGrid, XAxis, YAxis, Bar, Cell, ResponsiveContainer, Tooltip as ChartTooltip, Legend, Line, LabelList } from "recharts";
import Tooltip from "./tooltip";
import { RawData } from "../models";
import { formatMoney, formatNumber } from "../utils/formatter";

type Props = {
  data: RawData;
};

const StoreSummary2: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { t } = useTranslation("summary2");
  const [graphVisible, setGraphVisible] = React.useState(true);

  if (!data) {
    return <></>;
  }

  const graphData = [
    {
      increasedSalesFromLoyaltyOffers: data.IncrementalOfferSalesQuarterPreviousYearWeeklyAverage,
      increasedGrossMarginFromLoyaltyOffers: data.IncrementalOfferGMQuarterPreviousYearWeeklyAverage,
      name: t("thisQuarterLastYear"),
    },
    {
      increasedSalesFromLoyaltyOffers: data.IncrementalOfferSalesPreviousQuarterWeeklyAverage,
      increasedGrossMarginFromLoyaltyOffers: data.IncrementalOfferGMPreviousQuarterWeeklyAverage,
      name: t("lastQuarter"),
    },
    {
      increasedSalesFromLoyaltyOffers: data.IncrementalOfferSalesQTDWeeklyAverage,
      increasedGrossMarginFromLoyaltyOffers: data.IncrementalOfferGMQTDWeeklyAverage,
      name: t("thisQuarter"),
    },
    {
      increasedSalesFromLoyaltyOffers: data.IncrementalOfferSales,
      increasedGrossMarginFromLoyaltyOffers: data.IncrementalOfferGM,
      name: t("thisWeekHeading"),
    },
  ];

  const toggleGraph = (): void => {
    setGraphVisible(!graphVisible);
  };

  return (
    <>
      <Paper elevation={2} className="container-table">
        <h4>{t<string>("totalOfferTransactions")}</h4>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">{t<string>("restaurant")}</TableCell>
              <TableCell align="right">{t<string>("regionalAverage")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {t<string>("summary:offerTransactionsPercentageHeading")}
                <Tooltip i18nKey="offerTxnsTotal" />
              </TableCell>
              <TableCell align="right">{formatNumber(data.TransactionsWithOffersOverTransactions * 100)} %</TableCell>
              <TableCell align="right">{formatNumber(data.RegionalTransactionsWithOffersOverRegionalTransactions * 100)} %</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <FormControlLabel control={<Switch checked={graphVisible} onChange={toggleGraph} />} label={t<string>("graphHeading")} style={{ float: "right" }} />
      <TableContainer component={Paper}>
        <div hidden={!graphVisible} className="container-table">
          <h4>{t<string>("summaryHeading")}</h4>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={graphData} margin={{ top: 30, right: 10, left: 10, bottom: 30 }} barGap={0} barCategoryGap="15%">
              <CartesianGrid stroke="#eee" />
              <XAxis dataKey="name" tickLine={false} />
              <YAxis padding={{ bottom: 30 }} tickFormatter={(value): string => formatMoney(value, 0)} tickLine={false} />
              <ChartTooltip formatter={(value): string => (typeof value === "number" ? formatMoney(value).toString() : "")} />
              <Legend />
              <Bar isAnimationActive={false} dataKey="increasedSalesFromLoyaltyOffers" name={t<string>("increasedSalesFromLoyaltyOffers")} fill={"#ca9b71"}>
                {graphData.map((entry) => (
                  <Cell key={entry.name + "-cell"} fill={"#ca9b71"} />
                ))}

                <LabelList
                  dataKey="increasedSalesFromLoyaltyOffers"
                  formatter={(value): string => (typeof value === "number" ? formatMoney(value).toString() : "")}
                  position="top"
                />
              </Bar>

              <Line
                isAnimationActive={false}
                dataKey="increasedGrossMarginFromLoyaltyOffers"
                name={t<string>("increasedGrossMarginFromLoyaltyOffers")}
                stroke="#5f5b5b"
                strokeWidth={2}
              >
                <LabelList
                  dataKey="increasedGrossMarginFromLoyaltyOffers"
                  formatter={(value): string => (typeof value === "number" ? formatMoney(value).toString() : "")}
                  position="insideTop"
                />
              </Line>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </TableContainer>
    </>
  );
};

export default StoreSummary2;
