import React, { useEffect, useState } from "react";
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from "@material-ui/core";
import Tooltip from "./tooltip";
import { OfferIncrementalityData, OfferIncrementalityDataSet } from "../models";
import { useTranslation } from "react-i18next";
import { formatMoney, formatNumber } from "../utils/formatter";

type Props = {
  data: OfferIncrementalityDataSet;
  previousData: OfferIncrementalityDataSet;
  trailingWeeks: number;
};
type ImpactData = { raw: number; percentage: number };

const OfferIncrementality: React.FunctionComponent<Props> = ({ data, previousData, trailingWeeks }: Props) => {
  const [impact, setImpact] = useState({
    current: { cheque: { raw: 0, percentage: 0 }, traffic: { raw: 0, percentage: 0 } },
    previous: { cheque: { raw: 0, percentage: 0 }, traffic: { raw: 0, percentage: 0 } },
  });
  const { t } = useTranslation("offerIncrementality");

  const calculateImpact = ({ wOffers, woOffers }: OfferIncrementalityData): ImpactData => {
    const raw = wOffers - woOffers;
    return { raw, percentage: (raw / woOffers) * 100 };
  };

  useEffect(() => {
    setImpact({
      current: { cheque: calculateImpact(data.cheque), traffic: calculateImpact(data.traffic) },
      previous: { cheque: calculateImpact(previousData.cheque), traffic: calculateImpact(previousData.traffic) },
    });
  }, [data, previousData]);

  return (
    <>
      <Paper elevation={2} className="container-table">
        <h4>
          {t<string>("averageChequeByGuestTypeHeading")}
          <Tooltip i18nKey="averageChequeByGuestType" />
        </h4>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">{t<string>("thisWeekHeading")}</TableCell>
                <TableCell align="right">
                  {t<string>("trailingWeekAverageHeading", { trailingWeeks })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("guestWhoUsedOffersHeading")}
                </TableCell>
                <TableCell align="right">{formatMoney(data.cheque.wOffers)}</TableCell>
                <TableCell align="right">{formatMoney(previousData.cheque.wOffers)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("guestWhoDidNotUseOffersHeading")}
                </TableCell>
                <TableCell align="right">{formatMoney(data.cheque.woOffers)}</TableCell>
                <TableCell align="right">{formatMoney(previousData.cheque.woOffers)}</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("differenceInChequeHeading")}
                </TableCell>
                <TableCell align="right">{formatMoney(impact.current.cheque.raw)}</TableCell>
                <TableCell align="right">{formatMoney(impact.previous.cheque.raw)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
      <Paper elevation={2} className="container-table">
        <h4>
          {t<string>("weeklyChequeByGuestTypeHeading")}
          <Tooltip i18nKey="avgWklyFreqByGuestType" />
        </h4>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">{t<string>("thisWeekHeading")}</TableCell>
                <TableCell align="right">
                  {t<string>("trailingWeekAverageHeading", { trailingWeeks })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("guestWhoUsedOffersHeading")}
                </TableCell>
                <TableCell align="right">{formatNumber(data.traffic.wOffers)}</TableCell>
                <TableCell align="right">{formatNumber(previousData.traffic.wOffers)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("guestWhoDidNotUseOffersHeading")}
                </TableCell>
                <TableCell align="right">{formatNumber(data.traffic.woOffers)}</TableCell>
                <TableCell align="right">{formatNumber(previousData.traffic.woOffers)}</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t<string>("differenceInWeeklyHeading")}
                </TableCell>
                <TableCell align="right">{formatNumber(impact.current.traffic.raw)}</TableCell>
                <TableCell align="right">{formatNumber(impact.previous.traffic.raw)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default OfferIncrementality;
